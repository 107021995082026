import React from "react";
import normatividad from "../images/normatividad.svg";

function NormatividadIcon() {
    return (
        <img src={normatividad} alt=""/>
    );
}

export default NormatividadIcon;
