import { React, useState } from "react";
import "../styles/Contact.css";
import MyMap from "./MyMap";
import { ColorRing } from "react-loader-spinner";
import { IoIosCall, IoIosMail, IoMdPerson } from "react-icons/io";
import { CgDetailsMore } from "react-icons/cg";
import { FaCheckCircle } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa6";
import { MdOutlineError } from "react-icons/md";

const URL = 'https://quiet-canto-430022-g3.uc.r.appspot.com/alyfer/solicitud';

function Contact( { contactRef, formData, setFormData } ) {    
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const handleInputChange = (e) => {
        setShowErrorAlert(false);
        setShowSuccessAlert(false);

        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setShowSuccessAlert(false);
        setShowErrorAlert(false);
        setShowLoading(true);

        fetch(URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(data => {
            if (data.ok) {
                console.log("OK");
                setShowLoading(false);
                setShowSuccessAlert(true);
            } else {
                setShowLoading(false);
                setShowErrorAlert(true);
                console.log(data);
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setShowLoading(false);
            setShowErrorAlert(true);
        });
    };

    return (
        <div className="contact" ref={contactRef}>
            <h2 className="title">Contacto</h2>
            <div className="contact-info" data-aos="fade-up">
                <div className="contact-form">
                    <form onSubmit={handleSubmit}  className='form'>
                        <div className="form-title">
                            <p>Envíanos un mensaje</p>
                        </div>
                        <div className="input-group">
                            <div className="icon-box">
                                    <IoMdPerson />
                            </div>
                            <div className="input-box">
                                <label for="nombre" className={formData.nombre.trim() === '' ? '' : 'non-empty'}>
                                    Nombre y/o empresa<span className="required">*</span>
                                </label>
                                <input
                                    className="input"
                                    name="nombre"
                                    type="text"
                                    onChange={handleInputChange}
                                    value={formData.nombre}
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="icon-box">
                                    <IoIosMail />
                            </div>
                            <div className="input-box">
                                <label for="correo" className={formData.correo.trim() === '' ? '' : 'non-empty'}>
                                    Correo electronico<span className="required">*</span>
                                </label>
                                <input
                                    className="input"
                                    name="correo"
                                    type="mail"
                                    onChange={handleInputChange}
                                    value={formData.correo}
                                    required
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="icon-box">
                                    <IoIosCall />
                            </div>
                            <div className="input-box">
                                <label for="telefono" className={formData.telefono.trim() === '' ? '' : 'non-empty'}>
                                    Teléfono
                                </label>
                                <input
                                    className="input"
                                    name="telefono"
                                    type="tel"
                                    onChange={handleInputChange}
                                    value={formData.telefono}
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="icon-box">
                                    <CgDetailsMore />
                            </div>
                            <div className="input-box">
                                <label for="correo" className={formData.detalles.trim() === '' ? '' : 'non-empty'}>
                                    Mensaje<span className="required">*</span>
                                </label>
                                <textarea
                                    className="input textarea"
                                    type="text"
                                    name="detalles"
                                    required
                                    value={formData.detalles}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        {showErrorAlert ?
                            <div className="alert error">
                                Error al enviar solicitud, intente de nuevo por favor
                            </div>
                            :
                            null
                        }
                        <button className="btn btn-semi-dark btn-form">
                            {
                                showLoading ?
                                    <div className="loader">
                                        <ColorRing
                                            visible={true}
                                            height="30"
                                            width="30"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={['#ffffff', '#e9ebea', '#ffffff', '#e9ebea', '#ffffff']}
                                        />
                                    </div>
                                :
                                    showSuccessAlert ?
                                        <FaCheckCircle className="success-icon"/>
                                    :
                                        showErrorAlert ?
                                            <MdOutlineError className="success-icon"/>
                                        :
                                            <p className="enviar">Enviar</p>
                            }
                        </button>
                        <div className="form-subtitle">
                            <p>o</p>
                        </div>
                        
                        <div className="socials">
                            <a href="https://api.whatsapp.com/send/?phone=5549459505&text=¡Hola%21" target="_blank" rel="noopener noreferrer">
                                <IoLogoWhatsapp className="wa-icon"/>
                            </a>
                            <a href="https://www.facebook.com/alyfer.com.mx" target="_blank" rel="noopener noreferrer">
                                <FaFacebook className="fb-icon"/>
                            </a>
                        </div>
                    </form>
                </div>
            </div>
            <div className="contact-map" data-aos="fade-up">
                <MyMap />
            </div>
        </div>
    );
}

export default Contact;
