import React from "react";
import logo2 from "../images/logo.png";
import "../styles/Logo.css";

function Logo2() {
    return (
        <img src={logo2} alt="" className="logo2"/>
    );
}

export default Logo2;
