import React, { useEffect, useRef } from 'react';
import './App.css';
import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const homeRef = useRef(null);
  const servicesRef = useRef(null);
  const usRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="App">
      <Header homeRef={homeRef} servicesRef={servicesRef} usRef={usRef} contactRef={contactRef}/>
      <Content homeRef={homeRef} servicesRef={servicesRef} usRef={usRef} contactRef={contactRef}/>
      <Footer />
    </div>
  );
}

export default App;
