import React, { useState } from "react";
import "../styles/Content.css";
import Main from "./Main";
import Services from "./Services";
import AboutUs from "./AboutUs";
import Contact from "./Contact";

function Content( { homeRef, servicesRef, usRef, contactRef } ) {
    const [formData, setFormData] = useState({
        nombre: '',
        correo: '',
        telefono: '',
        detalles: ''
    });

    return (
        <div className="content">
            <Main homeRef={homeRef} contactRef={contactRef} servicesRef={servicesRef}/>
            <Services contactRef={contactRef} servicesRef={servicesRef} formData={formData} setFormData={setFormData}/>
            <AboutUs usRef={usRef}/>
            <Contact contactRef={contactRef} formData={formData} setFormData={setFormData}/>
        </div>
    );
}

export default Content;
