import React, { useState } from "react";
import "../styles/Header.css";
import Logo2 from "./Logo2";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

function Header( { homeRef, servicesRef, usRef, contactRef } ) {
    const scrollToHome = () => {
        setNavVisible(prevState => !prevState);
        homeRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToServices = () => {
        setNavVisible(prevState => !prevState);
        servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToUs = () => {
        setNavVisible(prevState => !prevState);
        usRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToContact = () => {
        setNavVisible(prevState => !prevState);
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const [navVisible, setNavVisible] = useState(false);

    const handleMenuClick = () => {
        setNavVisible(prevState => !prevState);
    };

    return (
        <header className="header">
            <Logo2 />
            <div className="nav">
                <p className="link" onClick={scrollToHome}>Inicio</p>
                <p className="link" onClick={scrollToServices}>Servicios</p>
                <p className="link" onClick={scrollToUs}>Nosotros</p>
                <p className="link remark" onClick={scrollToContact}>Contacto</p>
            </div>
            <div className="menu-box">
                <HiOutlineMenuAlt3 onClick={handleMenuClick}/>
            </div>
            <div className={navVisible ? "mobile-nav" : "mobile-nav not-visible"}>
                    <p className="link" onClick={scrollToHome}>Inicio</p>
                    <p className="link" onClick={scrollToServices}>Servicios</p>
                    <p className="link" onClick={scrollToUs}>Nosotros</p>
                    <p className="link" onClick={scrollToContact}>Contacto</p>
            </div>
        </header>
    );

    //<h3 className="logo">Alyfer</h3>
}

export default Header;
