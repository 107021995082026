import React from "react";
import "../styles/Footer.css";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-main-info">
                <div className="company">
                    <h2>Alyfer</h2>
                </div>
                <div className="dir">
                    <p className="info-title">Dirección</p>
                    <p className="info-desc">Rossini 107, Int. 102, Colonia Ex-Hipodromo de Peralvillo, Delegacion Cuahtémoc, Ciudad de México, C. P. 06250.</p>
                </div>
                <div className="footer-contact">
                    <div className="mail">
                        <p className="info-title">Correo</p>
                        <p className="info-desc">aalcantara@alyfer.com.mx</p>
                    </div>
                    <div className="tel">
                        <p className="info-title">Teléfono</p>
                        <p className="info-desc">+52 (55) 5782 1422</p>
                    </div>
                    <div className="ws">
                        <p className="info-title">WhatsApp</p>
                        <p className="info-desc">+52 (55) 4945 9505</p>
                    </div>
                </div>
                <div className="footer-secondary-info">
                    <p className="">Copyright © 2024 ALYFER</p>
                </div>
            </div>
            
        </footer>
    );
}
/*<div className="footer-section">
                    <div>
                        <p className="info-title">Horario</p>
                        <p className="info-desc">Lunes a viernes: 9am - 5pm</p>
                        <p className="info-desc">Sábado:          Cita previa</p>
                        <p className="info-desc">Domingo:         Cerrado</p>
                    </div>
                </div>*/
export default Footer;
