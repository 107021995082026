import React from "react";
import "../styles/ServiceBox.css";

function ServiceBox( { serviceInfo, contactRef, formData, setFormData } ) {
    const scrollToContact = () => {
        const details = 'Hola! Me interesa un servicio de ' + serviceInfo.nombre.toUpperCase() + ' ';
        const { name, value } = { name: 'detalles', value: details };
        setFormData({
            ...formData,
            [name]: value,
        });
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="service-box" data-aos="fade-up">
            <div className="service-box-info">
                <p className="service-box-title">{serviceInfo.nombre}</p>
                <div className="service-box-icon-box">
                    <serviceInfo.icon className="service-box-icon"/>
                </div>
                <p className="service-box-subtitle">{serviceInfo.descripcion}</p>
                <ul>
                    {serviceInfo.detalles.map((item, index) => (
                        <li className="service-box-text">{item}</li>
                    ))}
                </ul>
            </div>
            <div className="service-box-btn">
                <button className="btn btn-semi-light" onClick={scrollToContact}>Solicitar</button>
            </div>
        </div>
    );
}

export default ServiceBox;
