import React from "react";
import "../styles/Services.css";
import ServiceBox from "./ServiceBox";

import { FaListCheck } from "react-icons/fa6";
import { MdApproval } from "react-icons/md";
import { FaPeopleRoof } from "react-icons/fa6";
import { FaEnvira } from "react-icons/fa";
import { FaGasPump } from "react-icons/fa6";
import NormatividadIcon from "./NormatividadIcon";

import bitacora from '../images/bitacora.png';
import permiso from '../images/permiso.png';
import social from '../images/social.png';
import ambiental from '../images/ambiental.png';
import gasolinera from '../images/gasolinera.png';
import normatividad from '../images/normatividad.png';

const serviciosInfo = [
    {
        nombre: "Elaboración de Bitácoras y Procedimientos",
        descripcion: "Elaboramos procedimientos de:",
        detalles: [
            "Pre-arranque (en su caso).",
            "Operación y mantenimiento para gasolineras.",
            "Plantas de almacenamiento y distribución.",
            "Bitácoras por normatividad."
        ],
        image: bitacora,
        icon: FaListCheck
    },
    {
        nombre: "Tramitología Federal y Local",
        descripcion: "Preparación y gestión de trámites como",
        detalles: [
            "Licencia Ambiental Única.",
            "Cédula de Operación anual.",
            "Registro como generador de residuos peligrosos.",
            "Dictamen en Instalaciones eléctricas.",
            "Elaboración de proyectos de diseño y construccion de estaciones de servicio."
        ],
        image: permiso,
        icon: MdApproval
    },
    {
        nombre: "En materia de Impacto Social",
        descripcion: "Evaluaciones de impacto social para proyectos nuevos o en operación:",
        detalles: [
            "Gasolineras.",
            "Autocosumos.",
            "Plantas de almacenamiento y distribución de petrolíferos.",
            "Otros."
        ],
        image: social,
        icon: FaPeopleRoof
    },
    {
        nombre: "En materia de Impacto Ambiental",
        descripcion: "",
        detalles: [
            "Manifestaciones de impacto ambiental en sus diferentes modalidades.",
            "Informes preventivos.",
            "Modificación a proyectos autorizados.",
            "Atención a condicionantes.",
            "Cambios de titularidad.",
            "Etc."
        ],
        image: ambiental,
        icon: FaEnvira
    },
    {
        nombre: "Permisos en materia de petrolíferos",
        descripcion: "Gestión de permisos en materia de petrolíferos:",
        detalles: [
            "Comercialización.",
            "Transporte.",
            "Distribución.",
            "Almacenamiento.",
            "Expendio.",
            "Importación ante la Secretaria de Energía."
        ],
        image: gasolinera,
        icon: FaGasPump
    },
    {
        nombre: "Asesoría y capacitación a gasolineras",
        descripcion: "",
        detalles: [
            "Asesoría técnica y legal.",
            "Capacitación y regularización a gasolineras respecto a la normatividad vigente aplicable.",
            "NOM-EM-002-ASEA-2016.",
            "NOM-EM-003-ASEA-2016.",
            "NOM-005-ASEA-2016.",
            "NOM-016-CRE-2016."
        ],
        image: normatividad,
        icon: NormatividadIcon
    }
]

function Services( { contactRef, servicesRef, formData, setFormData} ) {
    return (
        <div ref={servicesRef} className="services">
            <h2 className="title">Servicios</h2>
            <div className="services-boxes">
                {serviciosInfo.map((item, index) => (
                    <ServiceBox
                        serviceInfo={item}
                        contactRef={contactRef}
                        formData={formData}
                        setFormData={setFormData}
                    />
                ))}
            </div>
        </div>
    );
}

export default Services;
