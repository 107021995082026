import React from 'react';
import "../styles/Main.css";
/*
const images = [
    require("../images/1.jpg"),
    require("../images/2.jpg"),
    require("../images/3.jpg"),
    require("../images/4.jpg"),
    require("../images/5.jpg"),
    require("../images/6.jpg"),
    require("../images/7.jpg"),
    require("../images/8.jpg"),
];*/

function Main( { homeRef, contactRef, servicesRef } ) {
    /*const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const backgroundStyle = {
        backgroundImage: `linear-gradient(to top right, rgba(255, 239, 186, .5), rgb(255, 255, 255, .5)), url(${images[currentImageIndex]})`,
    };*/
    
    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToServices = () => {
        servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div ref={homeRef} className="main">
            <div
                /*style={backgroundStyle}*/
                className="images-box"
            >
                <div className="main-info-box-buttons">
                    <button onClick={scrollToContact} className="btn btn-semi-dark btn-contact">Contáctanos</button>
                    <button onClick={scrollToServices} className="btn btn-light">Más información</button>
                </div>
                <div className="main-info-box">
                    <p className="description">Brindamos asesoría, capacitación y gestión dirigidos al gremio gasolinero.</p>
                </div>
                <div className="main-info-box">
                    <p className="company-name">Ingeniería y Servicios Ambientales</p>
                </div>
            </div>
        </div>
    );

    //<p className="company-name">A l y f e r</p>
}

export default Main;
