import React from "react";
import { Map, Marker } from "pigeon-maps"
import { maptiler } from 'pigeon-maps/providers'

const MY_API_KEY = 'OLMlEgBlTbAuHJtQMWzN';
const MAP_ID = 'basic-v2'; //'streets'
const maptilerProvider = maptiler(MY_API_KEY, MAP_ID)

const COORDINATES = [19.457321879421198, -99.13579933583088];

function MyMap() {
    return (
        <Map
            provider={maptilerProvider}
            dprs={[1, 2]}
            //height={300}
            defaultCenter={COORDINATES}
            defaultZoom={13}
        >
            <Marker width={50} anchor={COORDINATES} />
        </Map>
      );
}

export default MyMap;
