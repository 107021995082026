import React from "react";
import "../styles/AboutUsBox.css";
import { MdSwipe } from "react-icons/md";

function AboutUsBox( { info } ) {
    return (
        <div 
            className="about-box"
        >
            <img src={info.image} alt="" className="about-box-img"/>
            <div className="about-box-text-box">
                <p className="about-box-text">{info.information}</p>
            </div>
            <div className="deslizar">
                <MdSwipe />
                <p>Deslice</p>
            </div>
        </div>
    );
}

export default AboutUsBox;
