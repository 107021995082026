import React from "react";
import "../styles/AboutUs.css";
import AboutUsBox from "./AboutUsBox";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { EffectCards, Autoplay } from 'swiper/modules';

import img1 from "../images/1.jpg";
import img2 from "../images/2.jpg";
import img3 from "../images/3.jpg";
import img4 from "../images/4.jpg";
import img5 from "../images/5.jpg";
import img6 from "../images/6.jpg";
import img7 from "../images/7.jpg";
import img8 from "../images/8.jpg";

const images = [
    require("../images/1.jpg"),
    require("../images/2.jpg"),
    require("../images/3.jpg"),
    require("../images/4.jpg"),
    require("../images/5.jpg"),
    require("../images/6.jpg"),
    require("../images/7.jpg"),
    require("../images/8.jpg"),
];

const gradient = "linear-gradient(to left bottom, rgb(182, 244, 146), rgb(51, 139, 147)), ";

const info = [
    {
        information: "Alyfer Ingeniería y Servicios Ambientales S. A. de C. V. (AISA S. A. de C. V.) inició de manera formal sus actividades empresariales en la Ciudad de México el año 2015.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[0]})`,
        },
        image: img1
    },
    {
        information: "La empresa está consolidada por profesionistas especializados en servicios de Ingeniería, Consultoría y Gestoría ambiental en el sector gasolinero.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[1]})`,
        },
        image: img2
    },
    {
        information: "Apoyamos, asesoramos y capacitamos a nuestros clientes en los requerimientos derivados de la reciente Reforma Energética a través de dependencias como ASEA, CRE, SENER, Secretaria de Economía, autoridades locales, entre otras.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[2]})`,
        },
        image: img3
    },
    {
        information: "Participamos en proyectos ejecutivos de diseño, construcción de estaciones de servicio, siendo el resultado, proyectos aprobados y finalizados.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[3]})`,
        },
        image: img4
    },
    {
        information: "Brindamos servicios preventivos y correctivos para la operación y mantenimiento de gasolineras y autoconsumos.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[4]})`,
        },
        image: img5
    },
    {
        information: "Nuestro objetivo principal es lograr una permanente mejora en nuestras actividades mediante la capacitación constante de nuestro personal, a fin de dar un servicio que asegure una entrega en tiempo y forma.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[5]})`,
        },
        image: img6
    },
    {
        information: "Garantizamos resultados y eficiencia en nuestro trabajo, generando permanencia y reconocimiento de nuestros clientes.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[6]})`,
        },
        image: img7
    },
    {
        information: "Hemos demostrado nuestra capacidad de gestión, conocimiento del entorno ambiental y social, y del análisis científico en proyectos del sector energético, tanto para entidades públicas, privadas y organismos no gubernamentales.",
        styleInfo: {
            backgroundImage: `${gradient}url(${images[7]})`,
        },
        image: img8
    }
];

function AboutUs( { usRef } ) {
    return(
        <div ref={usRef} className="about-us" data-aos="fade-up">
            <h2 className="title">Sobre nosotros</h2>
            <Swiper
                loop={true}
                effect={'cards'}
                grabCursor={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[EffectCards, Autoplay]}
                className="mySwiper"
            >
                {info.map((item, index) => (
                    <SwiperSlide>
                        <AboutUsBox info={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default AboutUs;
